import React, { memo } from 'react'
import DSELayout from '../components/DSE/DSELayout/DSELayout';
import CauseNpc from '../components/DSE/CauseNpc/CauseNpc';
import HowDiagnosed from '../components/DSE/HowDiagnosed/HowDiagnosed';
import Glossary from '../components/DSE/Glossary/Glossary';
import SignsOfNPC from '../components/DSE/SignsOfNPC/SignsOfNPC';
import epsteinAudio from '../assets/audios/BL-0004_Epstein-barr-virus_48k_stereo.mp3';
import headNeckAudio from '../assets/audios/BL-0005_Head-and-neck-cancer_48k_stereo.mp3';
import papillomaAudio from '../assets/audios/BL-0006_Human-papillomavirus_48k_stereo.mp3';

const GlossaryList = [
  {
    id: 'glossary-epstein',
    title: 'Epstein-Barr virus (EBV) ',
    audio: epsteinAudio,
    text: 'A common virus remaining inactive in most people. EBV is associated with certain cancers such as NPC, specifically types 2 and 3.'
  },
  {
    id: 'glossary-head-neck',
    title: 'Head and neck cancer',
    audio: headNeckAudio,
    text: 'Cancer that presents in the head or neck region (areas such as the nasal cavity, sinuses, throat, voice box, mouth, or salivary glands).'
  },
  {
    id: 'glossary-hpv',
    title: 'Human papillomavirus (HPV) ',
    audio: papillomaAudio,
    text: 'The most common sexually transmitted infection. HPV is associated with type 1 NPC.'
  }
];

const Risk = props => {
  return (
    <React.Fragment>
      <DSELayout
        canonicalURL='https://npcfacts.com/risk-factors-and-symptoms'
        title='Nasopharyngeal cancer (NPC) risk factors & symptoms'
        pageTitle='Nasopharyngeal cancer (NPC) risk factors & symptoms'
        description='Nasopharyngeal cancer: see some of its risk factors and symptoms'
        keyWords='Nasopharyngeal carcinoma; NPC treatments; NPC outcomes'
        >
          <CauseNpc />
          <SignsOfNPC />
          <HowDiagnosed />
          <Glossary className={'dse-grad-bg3'} glossaryList={GlossaryList} />
      </DSELayout>
    </React.Fragment>
  )
}

export default memo(Risk)
