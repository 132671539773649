import React from 'react';
import * as styles from './SoundBalance.module.scss';
import clsx from 'clsx';

export default function SoundBalance({ className, balance, onChange }) {

  return (
    <section className={ clsx(styles.soundBalance, className) }>
      <button onClick={() => { onChange(-1) }} className={clsx(styles.option, balance === -1 && styles.selected)}>Left Ear</button>
      <button onClick={() => { onChange(0) }} className={clsx(styles.option, balance === 0 && styles.selected)}>Both</button>
      <button onClick={() => { onChange(1) }} className={clsx(styles.option, balance === 1 && styles.selected)}>Right Ear</button>
    </section>
  )
}

