import React from "react";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import IconTitleText from "../IconTitleText/IconTitleText";
import Title from "../title/title";
import icon1 from "../../../assets/images/pages/dse-home/DNA-icon.png";
import icon2 from "../../../assets/images/pages/dse-home/leaves-icon.png";
import icon3 from "../../../assets/images/pages/dse-home/virus-icon.png";

const CauseNpc = () => {
  return (
    <section className="dse-cause-npc" id="what-causes-npc">
      <DSEWrapper className="dse-cause-npc__title" fullWidth={true}>
        <Title text="WHAT CAUSES NASOPHARYNGEAL CANCER?" isH1={true}/>
      </DSEWrapper>
      <DSEWrapper className="dse-cause-npc__causes">
        <p className="dse-cause-npc__subtitle dse-light-text">While the exact cause is not known, there are risk factors associated with NPC.</p>
        <div className="dse-cause-npc__causes-list">
          <IconTitleText icon={icon1} title="Genetic factors" iconAlt="">
            <ul>
              <li>Most common in people of Southeast Asian descent</li>
              <li>2-3 times more likely to occur in males than females</li>
              <li>More likely to occur in family members of people with a medical history of NPC</li>
            </ul>
          </IconTitleText>
          <IconTitleText icon={icon2} title="Environmental factors" iconAlt="">
            <ul>
              <li>Alcohol or tobacco use</li>
              <li>Eating and cooking salt-preserved foods</li>
              <li>History of workplace exposures to wood dust and formaldehyde</li>
            </ul>
          </IconTitleText>
          <IconTitleText icon={icon3} title="Viral factors" iconAlt="">
            <ul>
              <li>Strong association with Epstein-Barr virus and types 2 and 3 NPC</li>
              <li>Possible association with human papillomavirus and type 1 NPC</li>
            </ul>
          </IconTitleText>
        </div>
      </DSEWrapper>
    </section>
  )
}

export default CauseNpc;