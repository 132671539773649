import React, { useState } from 'react';
import * as styles from './FrequencySlider.module.scss';
import clsx from 'clsx';
import { Range } from 'react-range';

export default function FrequencySlider({ className, initialValue=[50], min, max, step=1, marks=false, rtl=false, onChange }) {

  const [values, setValues] = useState([initialValue]);


  const handleOnChange = (values) => {
    if(onChange) {
      onChange(values);
    }
    setValues(values);
  };


  return (
    <section className={ clsx(styles.slider, className) }>
    <Range
      values={values}
      min={min}
      max={max}
      step={step}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            display: 'flex',
            width: '100%',
            border: '1px solid #d7d5d3',
            borderRadius: '.6rem',
            backgroundColor: '#daff30'
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: '1.2rem',
              width: '100%',
              borderRadius: '.6rem',
              background: '-moz-linear-gradient(90deg, rgba(218,255,49,1) 0%, rgba(218,255,49,1) 43%, rgba(209,231,91,1) 58%, rgba(73,91,23,1) 100%)',
              background: '-webkit-linear-gradient(90deg, rgba(218,255,49,1) 0%, rgba(218,255,49,1) 43%, rgba(209,231,91,1) 58%, rgba(73,91,23,1) 100%)',
              background: 'linear-gradient(90deg, rgba(218,255,49,1) 0%, rgba(218,255,49,1) 43%, rgba(209,231,91,1) 58%, rgba(73,91,23,1) 100%)',
              filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#daff31",endColorstr="#495b17",GradientType=1)',
              alignSelf: 'center'
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props, isDragged }) => (
        <div
          {...props}
          style={{
            ...props.style,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '5.3rem',
            width: '5.3rem',
            border: '3px solid black',
            borderRadius: '100%',
            background: 'radial-gradient(circle, transparent .4rem, white .4rem)'
          }}
        >
          <div
            style={{
              height: '1.3rem',
              width: '1.3rem',
              border: '.3rem solid black',
              borderRadius: '100%',
              backgroundColor: 'transparent'
            }}
          />
        </div>
      )}
      renderMark={ marks ? ({ props, index }) =>
        <div
          {...props}
          style={{
            ...props.style,
            height: '16px',
            width: '5px',
            backgroundColor: index * step < values[0] ? '#548BF4' : '#ccc'
          }}
        />
       : null }
      onChange={ handleOnChange }
    />

    </section>
  )
}

