// extracted by mini-css-extract-plugin
export var balanceSection = "SoundTest-module--balanceSection--50bf0";
export var cta = "SoundTest-module--cta--35e6e";
export var disclaimer = "SoundTest-module--disclaimer--37da6";
export var frequency = "SoundTest-module--frequency--743cf";
export var frequencyDesktop = "SoundTest-module--frequencyDesktop--d2e7a";
export var frequencyMobile = "SoundTest-module--frequencyMobile--ef774";
export var frequencySlider = "SoundTest-module--frequencySlider--e7ebc";
export var highlight = "SoundTest-module--highlight--ac1fc";
export var indication = "SoundTest-module--indication--f04f9";
export var list = "SoundTest-module--list--a5d4c";
export var playingButton = "SoundTest-module--playingButton--bf167";
export var soundBalance = "SoundTest-module--soundBalance--0a61c";
export var soundTest = "SoundTest-module--soundTest--84828";
export var subList = "SoundTest-module--subList--1f909";
export var subtitle = "SoundTest-module--subtitle--30983";
export var title = "SoundTest-module--title--d289d";
export var underline = "SoundTest-module--underline--dfbdc";