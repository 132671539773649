import React from "react";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import SoundTest from '../../DSE/SoundTest/SoundTest';
import DseCtaIcon from '../DseCtaIcon/DseCtaIcon';

const HowDiagnosed = () => {

  return (
    <section className="dse-how-diagnosed dse-grad-bg-darkgreen-to-darker" id="how-is-npc-diagnosed">
      <DSEWrapper className="dse-how-diagnosed__causes">
        <SoundTest />
        <DseCtaIcon text="See how nasopharyngeal cancer <br class='d-none d-md-block'/>is diagnosed and treated" href="/diagnosis-and-treatment/" />
      </DSEWrapper>
    </section>
  )
};

export default HowDiagnosed;