import React from 'react';
import Title from '../title/title';
import DSEWrapper from '../DSEWrapper/DSEWrapper';
import * as styles from './SignsOfNPC.module.scss';
import clsx from 'clsx';

export default function SignsOfNPC () {

  return (
    <section className={ clsx( styles.signsOfNPC, 'dse-grad-bg-green-to-dark') } id="signs-of-npc">
      <DSEWrapper fullWidth={true}>
        <Title className={ styles.title } text="WHAT ARE THE SYMPTOMS OF NASOPHARYNGEAL CANCER?"/>
      </DSEWrapper>
      <DSEWrapper>
        <p className={ clsx(styles.subtitle, 'dse-light-text') }>It can be difficult to recognize symptoms of nasopharyngeal cancer because they are similar to other conditions. Signs may include:</p>
        <ul className={ styles.list }>
          <li>Swelling or mass in the neck</li>
          <li>Hearing loss, pain, ringing, or fullness in the ear</li>
          <li>Nosebleeds</li>
          <li>Headaches</li>
          <li>Nasal obstruction or stuffiness</li>
          <li>Sore throat</li>
          <li>Fatigue</li>
          <li>Pain, numbness, or paralysis of face</li>
        </ul>
      </DSEWrapper>
    </section>
  )
};